import React from 'react'
import { formatImageTransform } from '../../lib/utils'

import Img from '../img/img'
import './hero.scss'

const SETTINGS = {
  sizes: '100vw',
}

function Hero({ imageData }) {
  if (!imageData) return <></>

  const { images, alt } = formatImageTransform(imageData)

  return (
    <figure className="hero">
      <Img sources={images} alt={alt} sizes={SETTINGS.sizes} lazy={true} />
    </figure>
  )
}

export default Hero
