import React from 'react';
import R from "ramda";

import GalleryVideo from "./galleryVideo";
import GalleryImage from "./galleryImage";
import GalleryCaption from "./galleryCaption";
import "./gallery.scss";

// Utils
const filterItems = items => {
	let filtered = { files: [], caption: false };

	R.reduce((filtered, item) => {
		if (item.__typename === "Craft_RowCaption") {
			filtered.caption = item.text;
		} else {
			filtered.files.push(item);
		}

		return filtered;
	}, filtered, items);

	return filtered;
};

// Components
const File = ({ file }) => (
	<figure className={ "gallery__item gallery__item--" + file.columns }>
		{{
			'Craft_RowImage': <GalleryImage file={file} />,
			'Craft_RowVideo': <GalleryVideo file={file} />,
		}[file.__typename]}
	</figure>
);

const Row = ({ items }) => {
	const { caption, files } = filterItems(items);

	return (
		<div className="gallery__row">
			{files.map(file => (
				<File file={file} />
			))}

			{caption && 
				<GalleryCaption>{caption}</GalleryCaption>
			}
		</div>
	);
};

const Gallery = ({ rows }) => (
	<div className="gallery">
		{rows.map(({ row }) => (
			<Row items={row} />
		))}
	</div>
);

export default Gallery;