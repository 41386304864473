import React from 'react'

import './close.scss'
import TransitionLinkHome from '../transitionLinkHome/transitionLinkHome'
import { useGlobalContext } from '../../state/global'

function Close() {
  const [{ isTouchDevice }] = useGlobalContext()

  return (
    <div className="close">
      <TransitionLinkHome className="close__btn" />
      {isTouchDevice && <div class="close__icon" />}
    </div>
  )
}

export default Close
