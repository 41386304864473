import React from "react";

import { Player, ControlBar, BigPlayButton } from "video-react";

const GalleryVideo = ({ file }) => {
	const files = file.file;

	return (
		<Player muted loop fluid autoPlay playsInline>

			{files.map(({ url }) => (
				<source src={url} />
			))}

			<ControlBar disabled />
			<BigPlayButton disabled />
		</Player>
	);
};

export default GalleryVideo;