import React from 'react'

import './description.scss'

const Description = ({ content }) => (
  <div
    className="description h1 underlineHover"
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

export default Description
