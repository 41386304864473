import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import * as utils from '../lib/utils'

// Hooks
import { useGlobalContext } from '../state/global'
import usePageStatus from '../hooks/usePageStatus/usePageStatus'

// Components
import Description from '../components/description/description'
import SEO from '../components/seo/seo'
import Hero from '../components/hero/hero'
import Title from '../components/title/title'
import Footnote from '../components/footnote/footnote'
import Gallery from '../components/gallery/gallery'
import Wrapper from '../components/wrapper/wrapper'
import Close from '../components/close/close'
import Modal from '../components/modal/modal'
import Inner from '../components/inner/inner'

const SETTINGS = {
  transitionDuration: 500,
}

export default ({ data, transitionStatus, entry, exit }) => {
  const {
    hero: [hero],
    projectDescription: { content: projectDescription },
    gallery: galleryRows,
  } = (data = utils.getEntryFromData(data))

  const footnote = data.footnote ? data.footnote.content : false
  const [, dispatchGlobal] = useGlobalContext()
  const modalElement = useRef(null)

  const setScrollTop = e => {
    dispatchGlobal({
      type: 'setScrollTop',
      newScrollTop: e.target.scrollTop,
    })
  }

  const setContainerHeight = () => {
    dispatchGlobal({
      type: 'setContainerHeight',
      newContainerHeight: modalElement.current.firstElementChild.offsetHeight,
    })
  }

  const setIsFilterOn = () => {
    dispatchGlobal({ type: 'setIsFilterOn', newIsFilterOn: true })
  }

  const setPage = () => {
    dispatchGlobal({ type: 'setPage', newPage: 'project' })
  }

  const setFilterDelayed = utils.delay(
    setIsFilterOn,
    SETTINGS.transitionDuration
  )

  useEffect(() => {
    setContainerHeight()
    setFilterDelayed()
    setPage()
    window.addEventListener('resize', setContainerHeight)

    return () => {
      window.removeEventListener('resize', setContainerHeight)
    }
  }, [])

  usePageStatus(transitionStatus, 'project')

  return (
    <>
      <SEO
        title={data.metaTitle || data.title}
        description={data.metaDescription}
      />
      <Modal
        setRef={modalElement}
        onScroll={setScrollTop}
        transitionStatus={transitionStatus}
        transition="move"
      >
        <Wrapper useMarginTop>
          <Hero imageData={hero} />
          <Inner>
            <Title>{data.subtitle}</Title>
            <Description content={projectDescription} />
            {galleryRows.length > 0 && <Gallery rows={galleryRows} />}
            {footnote && <Footnote content={footnote} />}
          </Inner>
        </Wrapper>
        <Close />
      </Modal>
    </>
  )
}

export const query = graphql`
  query($slug: String) {
    craft {
      entry(section: projects, slug: $slug) {
        ... on Craft_Projects {
          uri
          title
          subtitle
          projectDescription {
            content
          }
          hero {
            smUrl: url(transform: projectCardSm)
            smWidth: width(transform: projectCardSm)

            mdUrl: url(transform: projectCardMd)
            mdWidth: width(transform: projectCardMd)

            lgUrl: url(transform: projectCardLg)
            lgWidth: width(transform: projectCardLg)

            xlUrl: url(transform: projectCardXl)
            xlWidth: width(transform: projectCardXl)

            title
          }
          gallery {
            ... on Craft_GallerySuperTableBlockType {
              row {
                ... on Craft_RowImage {
                  file {
                    smUrl: url(transform: gallerySm)
                    smWidth: width(transform: gallerySm)

                    mdUrl: url(transform: galleryMd)
                    mdWidth: width(transform: galleryMd)

                    lgUrl: url(transform: galleryLg)
                    lgWidth: width(transform: galleryLg)

                    xlUrl: url(transform: galleryXl)
                    xlWidth: width(transform: galleryXl)

                    id
                    title
                    width
                    height
                  }
                  columns
                }

                ... on Craft_RowVideo {
                  file {
                    url
                  }
                  columns
                }

                ... on Craft_RowCaption {
                  text
                }
              }
            }
          }
          footnote {
            content
          }
          metaTitle
          metaDescription
        }
      }
    }
  }
`
