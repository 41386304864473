import React from "react";
import { formatImageTransform } from "../../lib/utils";

import Img from "../img/img";

const SETTINGS = {
	sizes: {
		3: '(min-width: 1200px) 250px, (min-width: 480px) 25vw, 100vw',
		4: '(min-width: 1200px) 353px, (min-width: 480px) 33vw, 100vw',
		5: '(min-width: 1200px) 460px, (min-width: 480px) 42vw, 100vw',
		6: '(min-width: 1200px) 560px, (min-width: 480px) 50vw, 100vw',
		7: '(min-width: 1200px) 660px, (min-width: 480px) 60vw, 100vw',
		8: '(min-width: 1200px) 770px, (min-width: 480px) 66vw, 100vw',
		9: '(min-width: 1200px) 870px, (min-width: 480px) 75vw, 100vw',
		10: '(min-width: 1200px) 970px, (min-width: 480px) 83vw, 100vw',
		11: '(min-width: 1200px) 1080px, (min-width: 480px) 90vw, 100vw',
		12: '(min-width: 1200px) 1200px, 100vw'
	}
};

const getSizes = columns => SETTINGS.sizes[columns];

const GalleryImage = ({ file: { file: [ file ], columns }}) => {
	const { images, alt } = formatImageTransform(file);
	const sizes = getSizes(columns);

	return (
		<Img
			sources={images}
			alt={alt}
			sizes={sizes}
			width={parseInt(file.width)}
			height={parseInt(file.height)}
			setPadding={true}
		/>
	);
};

export default GalleryImage;